<script>
    import { onMount } from "svelte";
    import { _ } from "svelte-i18n";
    import HeaderComponent from "../../../components/header.component.svelte";
    import { userInformation } from "../../../auth.service";
    import { params } from "../../../routeStore";
    import ListComponent from "./component.svelte";
    import CreateUpdatePropertyComponent from "./create-update.component.svelte";
    import { SubscriptionsService } from "../service";

    let loading = true;
    let configTitle = {
        breadcrumb: [],
        title: `Administrators`,
        description: ``,
        buttons: [
            {
                icon: "add",
                class: "btn-success",
                action: {
                    type: "event",
                    event: "addAdmin",
                    detail: {},
                },
            },
        ],
    };
    let modalCreateUser = false;

    onMount(() => {
        loading = true;

        initComponent();
    });

    const initComponent = () => {
        loading = true;

        SubscriptionsService.detail($params.subscriptionId)
            .then((response) =>
                response.ok ? response.json() : Promise.reject(response),
            )
            .then((responseSubscription) => {
                configTitle.breadcrumb = [
                    { title: `Home`, link: `/` },
                    {
                        title: `Settings`,
                        link: `/settings`,
                    },
                    {
                        title: `Subscriptions`,
                        link: `/settings/subscriptions`,
                    },
                    {
                        title: `${responseSubscription.name}`,
                        link: null,
                    },
                ];
                loading = false;
            });
    };

    const addAdminEvent = (e) => {
        modalCreateUser = true;
    };

    const CreateUpdateUserEvent = (response) => {
        modalCreateUser = false;
        initComponent();
    };
</script>

{#if !loading}
    <section class="container d-flex flex-column mh-100">
        <div class="d-flex flex-column">
            <HeaderComponent
                bind:config={configTitle}
                on:addAdmin={addAdminEvent}
            ></HeaderComponent>
        </div>

        <!-- Sección de contenido -->
        <div class="overflow-auto mh-100 flex-grow-1">
            <div class="card">
                <div class="card-body">
                    <!-- Contenido de Tabs -->
                    <ListComponent></ListComponent>
                </div>
            </div>
        </div>
    </section>

    {#if modalCreateUser}
        <CreateUpdatePropertyComponent
            on:successful={CreateUpdateUserEvent}
            on:close={() => (modalCreateUser = false)}
        ></CreateUpdatePropertyComponent>
    {/if}
{/if}
