<script>
    import { onMount, createEventDispatcher } from "svelte";
    import { _ } from "svelte-i18n";
    import { SubscriptionsService } from "./service";
    import {
        useForm,
        required,
        validators,
        maxLength,
        minLength,
        Hint,
        HintGroup,
        email,
    } from "svelte-use-form";
    import AutocompleteComponent from "../../components/Autocomplete.component.svelte";

    export let subscription = null;
    const dataForm = useForm();
    let loading = true;
    let dataSubscription = {
        name: "",
        ownerFirstName: "",
        ownerLastName: "",
        ownerAreaCode: "",
        ownerAreaCodeFull: "",
        ownerPhone: "",
        ownerEmail: "",
    };
    let disabledButton = false;
    let modalBackdrop;
    let countries = [];
    let resultsVersion = 0;

    $: {
        disabledButton = !$dataForm.valid;
    }

    onMount(() => {
        modalBackdrop.addEventListener("click", closeModal);

        loading = true;

        if (subscription) {
            SubscriptionsService.detail(subscription.id)
                .then((response) =>
                    response.ok ? response.json() : Promise.reject(response),
                )
                .then((response) => {
                    dataSubscription = Object.assign(
                        {},
                        dataSubscription,
                        response,
                    );
                    loading = false;
                })
                .catch((err) => {
                    console.error(err);
                });
        } else {
            loading = false;
        }
    });

    const closeModal = () => dispatch("close", true);

    const dispatch = createEventDispatcher();

    const handleSubmit = (e) => {
        disabledButton = true;
        if (dataSubscription.id) {
            SubscriptionsService.update(dataSubscription.id, dataSubscription)
                .then((response) =>
                    response.ok ? response : Promise.reject(response),
                )
                .then((response) => {
                    dispatch("successful", true);
                })
                .catch((err) => {
                    console.error(err);
                    disabledButton = false;
                });
        } else {
            SubscriptionsService.create(dataSubscription)
                .then((response) =>
                    response.ok ? response.json() : Promise.reject(response),
                )
                .then((response) => {
                    dispatch("successful", response);
                })
                .catch((err) => {
                    console.error(err);
                    disabledButton = false;
                });
        }
    };

    const onWritingEvent = (event) => {
        SubscriptionsService.listCountries({
            searchTxt: event.detail ? event.detail.toString().trim() : "",
            order: "name-asc",
        })
            .then((response) =>
                response.ok ? response.json() : Promise.reject(response),
            )
            .then((responseCountries) => {
                countries = responseCountries.map((r) =>
                    Object.assign({}, r, {
                        name: `(${r.areaCode}) ${r.name}`,
                    }),
                );
                ++resultsVersion;
            });
    };

    const onChangeEvent = (event) => {
        loading = true;
        dataSubscription.ownerAreaCode = event.detail.areaCode;
        dataSubscription.ownerAreaCodeFull = event.detail.name;
        resultsVersion = 0;
        setTimeout(() => {
            loading = false;
        }, 1);
    };
</script>

<!-- modal-dialog-scrollable -->
<div bind:this={modalBackdrop} class="modal-backdrop fade show" />
<div class="modal modal-right fade show" role="dialog">
    <div class="modal-content">
        {#if loading}
            <!-- Loading State -->
            <div class="modal-body">Cargando...</div>
        {:else}
            <!-- Modal Header -->
            <div class="modal-header">
                <h5 class="modal-title">
                    {#if subscription}
                        Modificar {subscription.name}
                    {:else}
                        Agregar subscripcion
                    {/if}
                </h5>
                <button type="button" class="btn-close" on:click={closeModal}
                ></button>
            </div>

            <!-- Modal Body -->
            <div class="modal-body">
                <form use:dataForm>
                    <div class="mb-3">
                        <label for="name" class="form-label">
                            {$_("Name")}
                        </label>
                        <!-- svelte-ignore a11y-autofocus -->
                        <input
                            use:validators={[
                                required,
                                minLength(3),
                                maxLength(20),
                            ]}
                            type="text"
                            class="form-control {$dataForm.name?.tuched
                                ? !$dataForm.name?.valid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                : ''}"
                            name="name"
                            id="name"
                            required
                            autofocus
                            autocomplete="off"
                            minlength="3"
                            maxlength="20"
                            bind:value={dataSubscription.name}
                        />
                        <HintGroup for="name">
                            <Hint
                                on="required"
                                class="form-text invalid-feedback"
                                >{$_("The field is mandatory.")}</Hint
                            >
                            <!-- Se muestra solo si el campo no está vacío (hideWhen="required") -->
                            <Hint
                                on="minLength"
                                class="form-text invalid-feedback"
                                hideWhen="required"
                                let:value
                                >{$_("The name must have at least")}
                                {value}
                                {$_("character.")}</Hint
                            >
                            <!-- Se muestra solo si el campo supera la longitud mínima (hideWhen="minLength") -->
                            <Hint
                                on="maxLength"
                                class="form-text invalid-feedback"
                                hideWhen="minLength"
                                let:value
                                >{$_("The name must not exceed")}
                                {value}
                                {$_("characters.")}</Hint
                            >
                        </HintGroup>
                    </div>
                    <div class="mb-3">
                        <label for="ownerFirstName" class="form-label">
                            {$_("Owner First Name")}
                        </label>
                        <input
                            use:validators={[
                                required,
                                minLength(3),
                                maxLength(20),
                            ]}
                            type="text"
                            class="form-control {$dataForm.ownerFirstName
                                ?.touched
                                ? !$dataForm.ownerFirstName?.valid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                : ''}"
                            name="ownerFirstName"
                            id="ownerFirstName"
                            required
                            autocomplete="off"
                            minlength="3"
                            maxlength="20"
                            bind:value={dataSubscription.ownerFirstName}
                        />
                        <HintGroup for="ownerFirstName">
                            <Hint
                                on="required"
                                class="form-text invalid-feedback"
                                >{$_("The field is mandatory.")}</Hint
                            >
                            <!-- Se muestra solo si el campo no está vacío (hideWhen="required") -->
                            <Hint
                                on="minLength"
                                class="form-text invalid-feedback"
                                hideWhen="required"
                                let:value
                                >{$_("The name must have at least")}
                                {value}
                                {$_("character.")}</Hint
                            >
                            <!-- Se muestra solo si el campo supera la longitud mínima (hideWhen="minLength") -->
                            <Hint
                                on="maxLength"
                                class="form-text invalid-feedback"
                                hideWhen="minLength"
                                let:value
                                >{$_("The name must not exceed")}
                                {value}
                                {$_("characters.")}</Hint
                            >
                        </HintGroup>
                    </div>

                    <div class="mb-3">
                        <label for="ownerLastName" class="form-label">
                            {$_("Owner Last Name")}
                        </label>
                        <input
                            use:validators={[
                                required,
                                minLength(3),
                                maxLength(20),
                            ]}
                            type="text"
                            class="form-control {$dataForm.ownerLastName
                                ?.touched
                                ? !$dataForm.ownerLastName?.valid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                : ''}"
                            name="ownerLastName"
                            id="ownerLastName"
                            required
                            autocomplete="off"
                            minlength="3"
                            maxlength="20"
                            bind:value={dataSubscription.ownerLastName}
                        />
                        <HintGroup for="ownerLastName">
                            <Hint
                                on="required"
                                class="form-text invalid-feedback"
                                >{$_("The field is mandatory.")}</Hint
                            >
                            <!-- Se muestra solo si el campo no está vacío (hideWhen="required") -->
                            <Hint
                                on="minLength"
                                class="form-text invalid-feedback"
                                hideWhen="required"
                                let:value
                                >{$_("The name must have at least")}
                                {value}
                                {$_("character.")}</Hint
                            >
                            <!-- Se muestra solo si el campo supera la longitud mínima (hideWhen="minLength") -->
                            <Hint
                                on="maxLength"
                                class="form-text invalid-feedback"
                                hideWhen="minLength"
                                let:value
                                >{$_("The name must not exceed")}
                                {value}
                                {$_("characters.")}</Hint
                            >
                        </HintGroup>
                    </div>

                    <div class="row mb-3">
                        <!-- Campo Area Code -->
                        <div class="col-md-6">
                            <AutocompleteComponent
                                placeholder={"EJ: +1 ...."}
                                value={dataSubscription.ownerAreaCodeFull}
                                label={"Area code"}
                                identifier={"autocomplet-" +
                                    dataSubscription.id}
                                required={true}
                                addedResult={false}
                                bind:results={countries}
                                bind:resultsVersion
                                on:onWriting={onWritingEvent}
                                on:onChange={onChangeEvent}
                            />
                        </div>

                        <!-- Campo Phone Number -->
                        <div class="col-md-6">
                            <label for="ownerPhone" class="form-label"
                                >{$_("Phone number")}</label
                            >
                            <input
                                use:validators={[required, maxLength(20)]}
                                type="text"
                                class="form-control {$dataForm.ownerPhone
                                    ?.touched
                                    ? !$dataForm.ownerPhone?.valid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    : ''}"
                                name="ownerPhone"
                                id="ownerPhone"
                                required
                                autocomplete="off"
                                maxlength="20"
                                bind:value={dataSubscription.ownerPhone}
                            />
                            <HintGroup for="ownerPhone">
                                <Hint
                                    on="required"
                                    class="form-text invalid-feedback"
                                >
                                    {$_("The field is mandatory.")}
                                </Hint>
                                <Hint
                                    on="maxLength"
                                    class="form-text invalid-feedback"
                                    hideWhen="required"
                                    let:value
                                >
                                    {$_("The name must not exceed")}
                                    {value}
                                    {$_("characters.")}
                                </Hint>
                            </HintGroup>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label for="ownerEmail" class="form-label">
                            {$_("Email")}
                        </label>
                        <input
                            use:validators={[required, email]}
                            type="email"
                            class="form-control {$dataForm.ownerEmail?.touched
                                ? !$dataForm.ownerEmail?.valid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                : ''}"
                            name="ownerEmail"
                            id="ownerEmail"
                            required
                            autocomplete="off"
                            bind:value={dataSubscription.ownerEmail}
                        />
                        <HintGroup for="ownerEmail">
                            <Hint
                                on="required"
                                class="form-text invalid-feedback"
                                >{$_("Email is mandatory.")}</Hint
                            >
                            <Hint
                                on="email"
                                class="form-text invalid-feedback"
                                hideWhen="required"
                                >{$_("The email format is not correct.")}</Hint
                            >
                        </HintGroup>
                    </div>
                </form>
            </div>

            <!-- Modal Footer -->
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-secondary"
                    on:click={closeModal}>Cerrar</button
                >
                <button
                    type="submit"
                    class="btn btn-primary"
                    disabled={!$dataForm.valid || disabledButton}
                    on:click={handleSubmit}>Guardar</button
                >
            </div>
        {/if}
    </div>
</div>
