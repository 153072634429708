<script>
    import { onMount, onDestroy } from "svelte";
    import { isSidebarCollapsed, isMobile, sidebarTop } from "./service";
    import { activeRoute } from "../routeStore";

    let loading = true;
    let sidebarElement;

    const menuItems = [
        {
            title: "Inicio",
            icon: "home",
            link: "/",
            items: [],
            notification: 0, // Si es 0, no se mostrará el badge
        },
        {
            title: "Dashboard",
            icon: "dashboard",
            link: "/dashboard",
            items: [],
            notification: 0, // Si es 0, no se mostrará el badge
        },
        // {
        //     title: "Settings",
        //     icon: "settings",
        //     link: "#",
        //     notification: 2, // Mostrará 2 notificaciones
        //     items: [
        //         {
        //             title: "Option 1",
        //             link: "#",
        //             icon: "settings",
        //             notification: 0,
        //         }, // Sin badge
        //         {
        //             title: "Option 2",
        //             link: "#",
        //             image: "path-to-image.jpg",
        //             notification: 1,
        //         }, // Badge con 1 notificación
        //         { title: "Option 3", link: "#", notification: 4 }, // Badge con 4 notificaciones
        //     ],
        // },
    ];

    const closeMenuOnClickOutside = (event) => {
        let collapsedInMObile = false;
        sidebarElement
            .querySelectorAll(".navbar .nav-item")
            .forEach((element) => {
                if (
                    element.contains(event.target) &&
                    !element.classList.contains("dropdown")
                ) {
                    collapsedInMObile = true;
                }
            });
        if (
            (!sidebarElement.contains(event.target) || collapsedInMObile) &&
            window.innerWidth < 992
        ) {
            $isSidebarCollapsed = true;
            $sidebarTop = null;
        }
    };

    const handleResize = () => {
        // Actualiza el estado 'isMobile' según el ancho de la ventana
        $isMobile = window.innerWidth < 768;
        // En móviles, inicia con el sidebar oculto (colapsado)
        $sidebarTop = null;
        if ($isMobile) {
            $isSidebarCollapsed = true; // Asegura que el sidebar esté oculto en móviles
        } else {
            // En desktop o tablet, ajusta el estado inicial si es necesario
            $isSidebarCollapsed = window.innerWidth < 992; // Por ejemplo, colapsado en tablet
        }
    };

    window.addEventListener("click", closeMenuOnClickOutside);

    window.addEventListener("resize", handleResize);
    handleResize();

    onMount(() => {
        // Cerrar el menú al hacer clic en un enlace del menú
        sidebarElement.querySelectorAll(".nav-link").forEach((element) => {
            element.addEventListener("click", closeMenuOnClickOutside);
        });
        initComponent();
    });

    onDestroy(() => {
        window.removeEventListener("click", closeMenuOnClickOutside);
        window.removeEventListener("resize", handleResize);
    });

    const initComponent = () => {
        loading = true;
        loading = false;
    };
</script>

<aside
    bind:this={sidebarElement}
    class="sidebar {$isSidebarCollapsed
        ? 'collapsed'
        : ''} overflow-auto d-flex flex-column"
    class:is-mobile={$isMobile}
    style={$isMobile && $sidebarTop ? "top: " + $sidebarTop + "px" : ""}
>
    {#if loading}
        Loading
    {:else}
        <nav class="navbar p-3">
            <ul class="navbar-nav flex-column w-100">
                <li class="nav-item">
                    <hr class="my-2" />
                </li>

                <!-- Inicio -->
                {#each menuItems as item}
                    <li
                        class="nav-item {$activeRoute.path == item.link
                            ? 'active'
                            : ''} {item.items.length > 0 ? 'dropdown' : ''} "
                    >
                        {#if item.items.length > 0}
                            <!-- Dropdown -->
                            <a
                                class="nav-link d-flex align-items-center"
                                href={item.link}
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                on:click={() => (item.open = !item.open)}
                            >
                                <span
                                    class="position-relative d-flex align-items-center"
                                >
                                    <span
                                        class="material-icons"
                                        style="font-size: 1.5rem;"
                                        >{item.icon}</span
                                    >

                                    {#if item.notification > 0}
                                        <span
                                            class="position-absolute top-0 end-0 badge rounded-pill bg-danger"
                                            style="font-size: 0.625rem; transform: translate(50%, -50%);"
                                        >
                                            {item.notification}
                                        </span>
                                    {/if}
                                </span>
                                <span class="nav-text ms-2">{item.title}</span>

                                <!-- Flecha de estado del dropdown -->
                                <span class="ms-auto d-flex align-items-center">
                                    <span
                                        class="material-icons dropdown-arrow"
                                        style="font-size: 1.5rem;"
                                    >
                                        {item.open
                                            ? "arrow_drop_down"
                                            : "arrow_right"}
                                    </span>
                                </span>
                            </a>

                            <!-- Subitems del dropdown -->
                            {#if item.open}
                                <ul class="dropdown-menu show">
                                    {#each item.items as subitem}
                                        <li>
                                            <a
                                                class="dropdown-item d-flex align-items-center"
                                                href={subitem.link}
                                            >
                                                {#if subitem.icon}
                                                    <!-- Si tiene ícono de Material Icons -->
                                                    <span
                                                        class="material-icons me-2"
                                                        style="font-size: 20px;"
                                                        >{subitem.icon}</span
                                                    >
                                                {:else if subitem.image}
                                                    <!-- Si tiene imagen -->
                                                    <img
                                                        src={subitem.image}
                                                        alt={subitem.title}
                                                        class="me-2"
                                                        style="width: 1.25rem; height: 1.25rem;"
                                                    />
                                                {/if}
                                                <span class="nav-text ms-2"
                                                    >{subitem.title}</span
                                                >

                                                <!-- Badge de notificación a la derecha del subitem -->
                                                {#if subitem.notification > 0}
                                                    <span
                                                        class="badge bg-danger ms-auto"
                                                        style="font-size: 0.625rem;"
                                                    >
                                                        {subitem.notification}
                                                    </span>
                                                {/if}
                                            </a>
                                        </li>
                                    {/each}
                                </ul>
                            {/if}
                        {:else}
                            <!-- Items regulares -->
                            <a
                                class="nav-link d-flex align-items-center"
                                href={item.link}
                            >
                                <span
                                    class="position-relative d-flex align-items-center"
                                >
                                    <span
                                        class="material-icons"
                                        style="font-size: 1.5rem;"
                                        >{item.icon}</span
                                    >

                                    {#if item.notification > 0}
                                        <span
                                            class="position-absolute top-0 end-0 badge rounded-pill bg-danger"
                                            style="font-size: 0.625rem; transform: translate(50%, -50%);"
                                        >
                                            {item.notification}
                                        </span>
                                    {/if}
                                </span>
                                <span class="nav-text ms-2">{item.title}</span>
                            </a>
                        {/if}
                    </li>
                {/each}

                <li class="nav-item">
                    <hr class="my-2" />
                </li>
            </ul>
        </nav>

        <div class="flex-grow-1"></div>

        <nav class="navbar p-3">
            <ul class="navbar-nav flex-column w-100">
                <!-- Link Settings -->
                <li
                    class="nav-item {$activeRoute.path &&
                    $activeRoute.path.includes(`/settings`)
                        ? 'active'
                        : ''}"
                >
                    <a
                        class="nav-link d-flex align-items-center"
                        href="/settings"
                    >
                        <span class="position-relative">
                            <span class="material-icons"> settings </span>
                            <!-- <span
                                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                            >
                                3
                            </span> -->
                        </span>
                        <span class="nav-text ms-2">Settings</span>
                    </a>
                </li>
            </ul>
        </nav>
    {/if}
</aside>
