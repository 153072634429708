<script>
    import { createEventDispatcher, onMount } from "svelte";
    import SkeletonLoader from "./skeletonLoader.component.svelte";

    export let placeholder = "";
    export let value = "";
    export let label = "";
    export let identifier = "";
    export let required = false;
    export let addedResult = false;
    export let results = []; // Resultados pasados desde el componente padre
    export let resultsVersion = 0; // Nueva propiedad para detectar cambios únicos en los resultados

    let inputValue = value;
    let loading = false; // Estado de carga interno
    let dropdownOpen = false; // Estado del dropdown interno
    let dirtyInput = false;
    let focusInput = false;
    let filteredResults = [];
    let inputElement;
    let containerElement;
    let dropdownElement;

    const dispatch = createEventDispatcher();

    // Reactividad: desactivar `loading` y actualizar resultados filtrados cuando `results` cambia
    $: if (resultsVersion >= 0) {
        loading = false; // Desactivar loading cuando llegan los resultados
        filteredResults = results;
    }

    // Manejar escritura en el input
    const handleInput = (event) => {
        dirtyInput = true;
        focusInput = true;
        inputValue = event.target.value;
        loading = true; // Activa el loading
        dropdownOpen = true; // Abre el dropdown
        dispatch("onWriting", inputValue); // Notifica al padre
    };

    // Seleccionar un resultado
    const selectResult = (result) => {
        inputValue = result.name;
        dropdownOpen = false; // Cierra el dropdown
        inputElement.blur();
        dispatch("onChange", result); // Notifica al padre
    };

    // Alternar el estado del dropdown
    const toggleDropdown = () => {
        loading = true; // Activa el loading
        dropdownOpen = true; // Abre el dropdown
        dispatch("onWriting", ""); // Notifica al padre
    };

    // Agregar un nuevo resultado si se permite
    const addNewResult = () => {
        if (addedResult && inputValue) {
            dropdownOpen = false;
            inputElement.blur();
            dispatch("onChange", { name: inputValue }); // Notifica al padre el nuevo valor
        }
    };

    // Cerrar el dropdown al hacer clic fuera del componente
    const handleClickOutside = (event) => {
        if (
            containerElement &&
            !containerElement.contains(event.target) &&
            dropdownElement &&
            !dropdownElement.contains(event.target)
        ) {
            dropdownOpen = false;
        }
    };

    // Escuchar clics fuera del componente
    onMount(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });
</script>

{#if label}
    <label for={identifier} class="form-label">{label}</label>
{/if}

<div class="input-group" bind:this={containerElement}>
    <input
        bind:this={inputElement}
        id={identifier}
        name={identifier}
        type="text"
        class="form-control {required && dirtyInput && !focusInput
            ? !inputValue || (inputValue && inputValue.trim().length == 0)
                ? 'is-invalid'
                : 'is-valid'
            : ''}"
        bind:value={inputValue}
        {placeholder}
        on:input={handleInput}
        on:focus={() => handleInput({ target: { value: inputValue } })}
        on:blur={() => {
            focusInput = false;
            dropdownOpen = false;
        }}
        class:invalid={required && !inputValue}
        autocomplete="off"
    />
    <button
        class="btn btn-sm btn-outline-secondary"
        type="button"
        on:click={toggleDropdown}
    >
        <span class="material-icons">arrow_drop_down</span>
    </button>
</div>
{#if required && dirtyInput && !focusInput && (!inputValue || (inputValue && inputValue.trim().length == 0))}
    <div class="form-text invalid-feedback" style="display: block;">
        {("The field is mandatory.")}
    </div>
{/if}

{#if dropdownOpen}
    <ul class="dropdown-menu show w-100" bind:this={dropdownElement}>
        {#if !loading}
            {#if filteredResults.length > 0}
                {#each filteredResults as result}
                    <li>
                        <button
                            type="button"
                            class="dropdown-item"
                            on:mousedown|preventDefault
                            on:click|preventDefault={() => selectResult(result)}
                        >
                            {result.name}
                        </button>
                    </li>
                {/each}
            {:else}
                <li>
                    <!-- svelte-ignore a11y-no-static-element-interactions -->
                    <span
                        class="dropdown-item text-muted"
                        on:mousedown|preventDefault>Sin resultados</span
                    >
                </li>
            {/if}
            {#if addedResult && inputValue.trim().length > 0}
                <li>
                    <button
                        type="button"
                        class="dropdown-item text-primary"
                        on:mousedown|preventDefault
                        on:click={addNewResult}
                    >
                        Agregar: "{inputValue}"
                    </button>
                </li>
            {/if}
        {:else}
            <li>
                <span class="dropdown-item text-muted">
                    <SkeletonLoader width="105px" height="14px" />
                </span>
            </li>
            <li>
                <span class="dropdown-item text-muted">
                    <SkeletonLoader width="85px" height="14px" />
                </span>
            </li>
            <li>
                <span class="dropdown-item text-muted">
                    <SkeletonLoader width="95px" height="14px" />
                </span>
            </li>
        {/if}
    </ul>
{/if}
