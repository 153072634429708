<script>
    import { onMount } from "svelte";
    import { _ } from "svelte-i18n";
    import { userInformation } from "../../auth.service";

    import ModalConfirmComponent from "../../components/modal-confirm.component.svelte";
    import { SubscriptionsService } from "./service";

    import LoadingComponent from "../../components/loading.component.svelte";

    let loading = {
        visible: true,
        currentStatus: "loading",
        loading: {
            color: "primary",
        },
        noResults: {
            title: "Sin Resultados",
            description:
                "No hay resultados para estos criterios de búsqueda. Intenta con otros términos.",
        },
        error: {
            title: "Error",
            description:
                "Ups, ha ocurrido un error inesperado. Inténtelo de nuevo más tarde.",
        },
        noData: {
            title: "Sin Datos",
            description: "No hay datos disponibles en este momento.",
        },
    };
    let subscriptions = [];
    let disabledAllButton = false;
    let filter = {
        searchTxt: "",
        order: "name-asc",
    };
    let modalUpdateSubscription = null;
    let modalConfirmDelete = null;
    let configModalConfirmDelete = {
        iconClosed: true,
        title: "Eliminar usuario",
        description: "¿Estas seguro que deseas eliminar a #name#?",
        buttonCancel: "Cancel",
        buttonSubmit: "Delete",
        typeSubmit: "danger",
        disabledButton: false,
    };

    onMount(() => {
        loading.visible = true;

        initComponent();
    });

    const initComponent = () => {
        loading.currentStatus = "loading";
        loading.visible = true;
        SubscriptionsService.list(filter)
            .then((response) =>
                response.ok ? response.json() : Promise.reject(response),
            )
            .then((response) => {
                subscriptions = response;

                if (
                    response.length == 0 &&
                    filter.searchTxt &&
                    filter.searchTxt.length > 0
                ) {
                    loading.currentStatus = "noResults";
                } else if (
                    response.length == 0 &&
                    filter.searchTxt.length == 0
                ) {
                    loading.currentStatus = "noData";
                } else {
                    loading.visible = false;
                }
            })
            .catch((err) => {
                console.error(err);
                loading.currentStatus = "failed";
            });
    };

    const CreateUpdateUserEvent = (event) => {
        modalUpdateUser = null;
        initComponent();
    };

    const openModalConfirmDelete = (user) => {
        configModalConfirmDelete.description =
            configModalConfirmDelete.description.replace(
                new RegExp("#name#", "ig"),
                `${user.firstName} ${user.lastName}`,
            );
        modalConfirmDelete = user;
    };

    const closeModalConfirmDelete = (user) => {
        configModalConfirmDelete.description =
            configModalConfirmDelete.description.replace(
                new RegExp(
                    `${modalConfirmDelete.firstName} ${modalConfirmDelete.lastName}`,
                    "ig",
                ),
                "#name#",
            );
        modalConfirmDelete = null;
    };

    const deleteUserById = () => {
        if (!disabledAllButton) {
            disabledAllButton = true;
            SubscriptionsService.deleteAdmin(modalConfirmDelete.id)
                .then((response) =>
                    response.ok ? response : Promise.reject(response),
                )
                .then((response) => {
                    disabledAllButton = false;
                    closeModalConfirmDelete();
                    initComponent();
                })
                .catch((err) => {
                    console.error(err);
                    disabledAllButton = false;
                });
        }
    };

    const searchApiKeys = (event) => {
        if (["ArrowDown", "ArrowUp", "Enter", "Tab"].includes(event.key))
            return;
        initComponent();
    };

    const onChangeOrder = (event) => {
        initComponent();
    };
</script>

<div style="min-height: 10rem;">
    <!-- Search and Filter Row -->
    <div class="row mb-3">
        <div class="col-md-8">
            <input
                type="text"
                class="form-control"
                placeholder="Buscar la subscripción"
                bind:value={filter.searchTxt}
                on:keyup={searchApiKeys}
                autocomplete="off"
            />
        </div>
        <div class="col-md-4">
            <select
                class="form-select"
                bind:value={filter.order}
                on:change={onChangeOrder}
            >
                <option value="name-asc">Nombre Ascendente</option>
                <option value="name-desc">Nombre Descendente</option>
            </select>
        </div>
    </div>

    {#if !loading.visible}
        <!-- User List -->
        {#each subscriptions as subscription}
            <div class="row align-items-center border-bottom py-2">
                <div class="col-auto">
                    <img
                        src={subscription.picture}
                        class="rounded-circle"
                        alt="{subscription.firstName} {subscription.lastName}"
                    />
                </div>
                <div class="col">
                    <div class="fw-bold">
                        {subscription.name}
                    </div>
                    <p class="card-text">
                        <span class="badge bg-secondary">1 propiedad</span>
                    </p>
                </div>
                <!-- <div class="col d-none d-md-block">98</div>
                <div class="col d-none d-sm-block">4 hours ago</div> -->
                <div class="col text-end">
                    <div class="dropdown">
                        <button
                            class="btn btn-link btn-sm"
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i class="material-icons">more_vert</i>
                        </button>
                        <ul
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                        >
                            <li>
                                <!-- svelte-ignore a11y-invalid-attribute -->
                                <a
                                    class="dropdown-item"
                                    href="javascript:;"
                                    on:click|preventDefault={() =>
                                        (modalUpdateSubscription =
                                            subscription)}>Editar</a
                                >
                            </li>
                            <li>
                                <!-- svelte-ignore a11y-invalid-attribute -->
                                <a
                                    class="dropdown-item"
                                    href="javascript:;"
                                    on:click|preventDefault={() =>
                                        openModalConfirmDelete(subscription)}
                                    >Eliminar</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        {/each}
    {:else}
        <LoadingComponent bind:config={loading}></LoadingComponent>
    {/if}
</div>

{#if modalConfirmDelete}
    <ModalConfirmComponent
        bind:config={configModalConfirmDelete}
        on:close={closeModalConfirmDelete}
        on:submit={deleteUserById}
    ></ModalConfirmComponent>
{/if}

<!-- {#if modalUpdateSubscription}
    <CreateUpdateUserComponent
        bind:user={modalUpdateSubscription}
        on:successful={CreateUpdateUserEvent}
        on:close={() => (modalUpdateSubscription = null)}
    ></CreateUpdateUserComponent>
{/if} -->
