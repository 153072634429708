<script>
    import { onMount } from "svelte";
    import { _ } from "svelte-i18n";

    export let config;
    const defaultConfig = {
        currentStatus: "loading", // 'loading', 'success', 'failed', 'warning', 'noResults', 'noData'
        loading: {
            title: "Cargando",
            description:
                "Estamos procesando tu solicitud. Por favor, espera un momento.",
            color: "primary",
        },
        success: {
            title: "Proceso Exitoso",
            description: "La operación se ha completado con éxito.",
        },
        error: {
            title: "Error",
            description:
                "Ups, ha ocurrido un error inesperado. Inténtelo de nuevo más tarde.",
        },
        warning: {
            title: "Advertencia",
            description:
                "Ha ocurrido una advertencia. Por favor, revisa los detalles.",
        },
        noResults: {
            title: "Sin Resultados",
            description:
                "No hay resultados para estos criterios de búsqueda. Intenta con otros términos.",
        },
        noData: {
            title: "Sin Datos",
            description:
                "No hay datos disponibles en este momento. ¡Anímate a crear!",
        },
    };

    onMount(() => {
        config = Object.assign({}, defaultConfig, config);
    });
</script>

<div class="mt-5 mb-5 d-flex justify-content-center align-items-center">
    <div class="text-center">
        {#if config.currentStatus === "loading"}
            <div
                class="spinner-border text-{config.loading.color}"
                role="status"
            >
                <span class="visually-hidden">{$_(config.loading.title)}</span>
            </div>
            {#if config.loading.title}
                <h2 class="mt-3">{$_(config.loading.title)}</h2>
            {/if}
            {#if config.loading.description}
                <p>{$_(config.loading.description)}</p>
            {/if}
        {:else if config.currentStatus === "success"}
            <i class="material-icons" style="font-size:48px;color:green;"
                >check_circle</i
            >
            {#if config.success.title}
                <h2 class="mt-3">{$_(config.success.title)}</h2>
            {/if}
            {#if config.success.description}
                <p>{$_(config.success.description)}</p>
            {/if}
        {:else if config.currentStatus === "failed"}
            <i class="material-icons" style="font-size:48px;color:red;">error</i
            >
            {#if config.error.title}
                <h2 class="mt-3">{$_(config.error.title)}</h2>
            {/if}
            {#if config.error.description}
                <p>{$_(config.error.description)}</p>
            {/if}
        {:else if config.currentStatus === "warning"}
            <i class="material-icons" style="font-size:48px;color:orange;"
                >warning</i
            >
            {#if config.warning.title}
                <h2 class="mt-3">{$_(config.warning.title)}</h2>
            {/if}
            {#if config.warning.description}
                <p>{$_(config.warning.description)}</p>
            {/if}
        {:else if config.currentStatus === "noResults"}
            <i class="material-icons" style="font-size:48px;color:gray;"
                >search_off</i
            >
            {#if config.noResults.title}
                <h2 class="mt-3">{$_(config.noResults.title)}</h2>
            {/if}
            {#if config.noResults.description}
                <p>{$_(config.noResults.description)}</p>
            {/if}
        {:else if config.currentStatus === "noData"}
            <i class="material-icons" style="font-size:48px;color:blue;">info</i
            >
            {#if config.noData.title}
                <h2 class="mt-3">{$_(config.noData.title)}</h2>
            {/if}
            {#if config.noData.description}
                <p>{$_(config.noData.description)}</p>
            {/if}
        {/if}
    </div>
</div>
