import Page from "./page.svelte";
import AccessRoutes from "./access/Routes";
import SubscriptionsRoutes from "./subscriptions/Routes";

export default ([
    {
        path: '',
        component: Page
    },
    {
        path: 'access',
        children: AccessRoutes
    },
    {
        path: 'subscriptions',
        children: SubscriptionsRoutes
    }
]);