import { environments } from "../../assets/environments";
import { httpDelete, httpGet, httpPost, httpPut } from "../../http-service.svelte";

const listCountries = (input) =>
    httpGet(
        `${environments.administrationApi}/countries?${input.searchTxt && input.searchTxt.length > 0
            ? `searchTxt=${encodeURIComponent(input.searchTxt)}`
            : ``}${input.order
                ? `&order=${encodeURIComponent(input.order)}`
                : ``}`
    );

const list = (input) =>
    httpGet(
        `${environments.administrationApi}/subscriptions?${input.searchTxt && input.searchTxt.length > 0
            ? `searchTxt=${encodeURIComponent(input.searchTxt)}`
            : ``}${input.order
                ? `&order=${encodeURIComponent(input.order)}`
                : ``}`
    );

const deleteSubscription = (id) =>
    httpDelete(
        `${environments.administrationApi}/subscription/${id}`,
        {}
    );

const detail = (id) =>
    httpGet(
        `${environments.administrationApi}/subscription/${id}`
    );

const create = (input) =>
    httpPost(
        `${environments.administrationApi}/subscription`,
        input
    );

const update = (id, input) =>
    httpPut(
        `${environments.administrationApi}/subscription/${id}`,
        input
    );

export const SubscriptionsService = ({
    listCountries,
    list,
    deleteSubscription,
    detail,
    create,
    update
});