import { environments } from "../../../assets/environments";
import { httpDelete, httpGet, httpPost, httpPut } from "../../../http-service.svelte";

const listPermissions = () =>
    httpGet(
        `${environments.administrationApi}/permissions`
    );

const list = (input) =>
    httpGet(
        `${environments.administrationApi}/user-types?${input.searchTxt && input.searchTxt.length > 0
            ? `searchTxt=${encodeURIComponent(input.searchTxt)}`
            : ``}${input.order
                ? `&order=${encodeURIComponent(input.order)}`
                : ``}`
    );

const deleteUserType = (id) =>
    httpDelete(
        `${environments.administrationApi}/user-type/${id}`,
        {}
    );

const detail = (id) =>
    httpGet(
        `${environments.administrationApi}/user-type/${id}`
    );

const create = (input) =>
    httpPost(
        `${environments.administrationApi}/user-type`,
        input
    );

const update = (id, input) =>
    httpPut(
        `${environments.administrationApi}/user-type/${id}`,
        input
    );

export const UserTypesService = ({
    listPermissions,
    list,
    deleteUserType,
    detail,
    create,
    update
});