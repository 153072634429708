import Page from "./page.svelte";
import PropertiesRoutes from "./properties/Routes";
import AdministratorsRoutes from "./administrators/Routes";

export default ([
    {
        path: '',
        component: Page
    },
    {
        path: ':subscriptionId/properties',
        children: PropertiesRoutes
    },
    {
        path: ':subscriptionId/administrators',
        children: AdministratorsRoutes
    },
    {
        path: ':subscriptionId',
        redirectTo: './properties'
    }
]);