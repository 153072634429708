<script>
    import { format } from "date-fns";
    import SkeletonLoader from "../components/skeletonLoader.component.svelte";

    export let item;
</script>

<div class="card">
    <div class="row m-0 align-items-center py-2 border-bottom">
        {#if item.loading}
            <SkeletonLoader height="3rem" />
        {:else}
            <div
                class="col-12 col-sm-6 col-md-4 col-lg-1 d-flex justify-content-center"
            >
                {#if item.loading}
                    <SkeletonLoader height="1rem" width="1rem" />
                {:else}
                    <label>
                        <input type="checkbox" name="" id="" />
                    </label>
                {/if}
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
                {#if item.loading}
                    <SkeletonLoader height="1.3rem" />
                {:else}
                    <div class="dropdown">
                        <button
                            class="btn btn-dark btn-sm w-100 d-flex align-items-center justify-content-center gap-2"
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            Nuevo
                            <i class="material-icons" style="font-size: 16px;"
                                >edit</i
                            >
                        </button>
                        <ul
                            class="dropdown-menu w-100 text-center"
                            aria-labelledby="dropdownMenuButton"
                        >
                            <li>
                                <a class="dropdown-item bg-primary" href="#"
                                    >Primary</a
                                >
                            </li>
                            <li>
                                <a class="dropdown-item bg-secondary" href="#"
                                    >Secondary</a
                                >
                            </li>
                            <li>
                                <a class="dropdown-item bg-success" href="#"
                                    >Success</a
                                >
                            </li>
                            <li>
                                <a class="dropdown-item bg-danger" href="#"
                                    >Danger</a
                                >
                            </li>
                            <li>
                                <a class="dropdown-item bg-warning" href="#"
                                    >Warning</a
                                >
                            </li>
                            <li>
                                <a class="dropdown-item bg-info" href="#"
                                    >Info</a
                                >
                            </li>
                            <li>
                                <a class="dropdown-item bg-dark" href="#"
                                    >Dark</a
                                >
                            </li>
                            <li>
                                <a
                                    class="dropdown-item bg-light bg-dark"
                                    href="#">Light</a
                                >
                            </li>
                        </ul>
                    </div>
                {/if}
                {#if item.loading}
                    <SkeletonLoader height="0.7rem" />
                {:else}
                    <div class="d-flex align-items-center mt-2">
                        <div
                            class="progress flex-grow-1"
                            style="height: 0.5rem;"
                        >
                            <div
                                class="progress-bar bg-success"
                                role="progressbar"
                                style="width: 50%;"
                                aria-valuenow="50"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        <div
                            class="ms-3 text-nowrap"
                            style="font-size: 0.7rem;"
                        >
                            <strong>1h 10m</strong>
                        </div>
                    </div>
                {/if}
            </div>
            <div
                class="col-12 col-sm-6 col-md-4 col-lg-1 d-flex justify-content-center"
            >
                {#if item.loading}
                    <SkeletonLoader height="2rem" width="3rem" />
                {:else}
                    <img
                        src="https://files-api.mbizera.norelian.com/67b485240fadb9f298a09f3e"
                        alt=""
                        class="w-75"
                    />
                {/if}
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
                {#if item.loading}
                    <SkeletonLoader height="1rem" />
                {:else}
                    <strong>420</strong>
                {/if}
                {#if item.loading}
                    <SkeletonLoader height="1rem" />
                {:else}
                    <p class="mb-0">Max Bizera</p>
                {/if}
            </div>
            <div
                class="col-12 col-sm-6 col-md-4 col-lg-1 d-flex justify-content-center"
            >
                {#if item.loading}
                    <SkeletonLoader height="1.5rem" width="1.5rem" />
                {:else}
                    <div class="position-relative">
                        <div
                            class="bg-secondary rounded-circle p-2 d-flex justify-content-center align-items-center"
                            style="width: 40px; height: 40px;"
                        >
                            <i class="material-icons text-white">home</i>
                        </div>
                        {#if item.reservationVip}
                            <i
                                class="material-icons position-absolute top-0 start-100 translate-middle text-warning rounded-circle p-1"
                                style="font-size: 12px;"
                            >
                                star
                            </i>
                        {/if}
                    </div>
                {/if}
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3">
                {#if item.loading}
                    <SkeletonLoader height="1rem" />
                {:else}
                    <strong
                        class="d-inline-block text-truncate w-100"
                        style="max-width: 100%;"
                        >Subcategory / Category / Department</strong
                    >
                {/if}
                {#if item.loading}
                    <SkeletonLoader height="1rem" />
                {:else}
                    <p class="mb-0">Esto es una descripción</p>
                {/if}
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-2">
                <div class="row align-items-center justify-content-end m-0">
                    <div class="col-12 text-end">
                        {#if item.loading}
                            <SkeletonLoader height="1rem" />
                        {:else}
                            <strong
                                >{format(
                                    new Date(),
                                    "dd-MM-yyyy HH:mm",
                                )}</strong
                            >
                        {/if}
                    </div>
                    <div class="col-2 p-0 pe-1"></div>
                    <div class="col-2 p-0 pe-1">
                        {#if item.loading}
                            <SkeletonLoader height="1rem" />
                        {:else}
                            <i class="material-icons">star</i>
                        {/if}
                    </div>
                    <div class="col-2 p-0 pe-1">
                        {#if item.loading}
                            <SkeletonLoader height="1rem" />
                        {:else if !item.reservationVip}
                            <i class="material-icons">edit</i>
                        {/if}
                    </div>
                    <div class="col-2 p-0 pe-1">
                        {#if item.loading}
                            <SkeletonLoader height="1rem" />
                        {:else}
                            <i class="material-icons">delete</i>
                        {/if}
                    </div>
                    <div class="col-4 p-0 pe-1 text-end">
                        {#if item.loading}
                            <SkeletonLoader height="1rem" />
                        {:else}
                            <p class="mb-0">#123</p>
                        {/if}
                    </div>
                </div>
            </div>
        {/if}
    </div>
</div>
