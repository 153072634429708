<script>
    import { onMount } from "svelte";
    import { _ } from "svelte-i18n";
    import DashboardService from "../service";

    import LoadingComponent from "../../components/loading.component.svelte";

    export let filter;
    let loading = {
        visible: true,
        currentStatus: "loading",
        loading: {
            color: "primary",
        },
        noResults: {
            title: "Sin Resultados",
            description:
                "No hay resultados para estos criterios de búsqueda. Intenta con otros términos.",
        },
        error: {
            title: "Error",
            description:
                "Ups, ha ocurrido un error inesperado. Inténtelo de nuevo más tarde.",
        },
    };

    const columnsName = [
        {
            codename: "name",
            name: "Name",
            tooltip: "Nombre de la propiedad",
        },
        {
            codename: "total",
            name: "Total",
            tooltip: "Total",
        },
        {
            codename: "emailCount",
            name: "Emails",
            tooltip: "Cantidad de emails enviados",
        },
        {
            codename: "smsCount",
            name: "SMS",
            tooltip: "Cantidad de SMS enviados",
        },
        {
            codename: "checkInCount",
            name: "Check-ins",
            tooltip: "Cantidad de Check-ins",
        },
        {
            codename: "taskCount",
            name: "Tasks",
            tooltip: "Cantidad de Tareas",
        },
        {
            codename: "normalCount",
            name: "Solicitudes",
            tooltip: "Cantidad de Solicitudes",
        },
        {
            codename: "restaurantCount",
            name: "Restaurant",
            tooltip: "Cantidad de Restaurantes",
        },
        {
            codename: "serviceCount",
            name: "Service",
            tooltip: "Cantidad de Servicios",
        },
        {
            codename: "lostFoundCount",
            name: "Lost & Found",
            tooltip: "Cantidad de Objetos Perdidos",
        },
        {
            codename: "packageCount",
            name: "Packages",
            tooltip: "Cantidad de Paquetes",
        }
    ];
    let listProperties = [];

    let currentSortColumn = "total";
    let sortOrder = "desc";

    onMount(() => {
        loading.visible = true;
        initComponent();
    });

    const initComponent = () => {
        loading.currentStatus = "loading";
        loading.visible = true;
        DashboardService.propertiesListConsume(
            Object.assign({}, filter, {
                currentSortColumn,
                sortOrder,
            }),
        )
            .then((response) =>
                response.ok ? response.json() : Promise.reject(response),
            )
            .then((response) => {
                listProperties = response;
                if (response.length == 0) {
                    loading.currentStatus = "noResults";
                } else {
                    loading.visible = false;
                }
            })
            .catch((err) => {
                console.error(err);
                loading.currentStatus = "failed";
            });
    };

    const setOrdenFor = (codename) => {
        if (currentSortColumn == codename) {
            sortOrder = sortOrder == "desc" ? "asc" : "desc";
        } else {
            currentSortColumn = codename;
            sortOrder = "desc";
        }
        initComponent();
    };
</script>

<div class="card">
    <div class="card-body">
        {#if !loading.visible}
            <h2 class="card-title">Uso de propiedades</h2>

            <table class="table table-striped table-hover table-responsive">
                <thead class="table-dark">
                    <tr>
                        {#each columnsName as column}
                            <th
                                class="sortable"
                                on:click={() => setOrdenFor(column.codename)}
                                title={column.tooltip}
                            >
                                {column.name}
                                {#if currentSortColumn == column.codename}
                                    <i class="material-icons pointer"
                                        >{sortOrder == "desc"
                                            ? "arrow_downward"
                                            : "arrow_upward"}</i
                                    >
                                {:else}
                                    <i class="material-icons pointer">sort</i>
                                {/if}
                            </th>
                        {/each}
                    </tr>
                </thead>
                <tbody>
                    {#each listProperties as property}
                        <tr>
                            {#each columnsName as column}
                                <td title={column.tooltip}
                                    >{property[column.codename]}</td
                                >
                            {/each}
                        </tr>
                    {/each}
                </tbody>
            </table>
        {:else}
            <LoadingComponent bind:config={loading}></LoadingComponent>
        {/if}
    </div>
</div>
