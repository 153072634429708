<script>
    import { onMount } from "svelte";
    import { _ } from "svelte-i18n";
    import HeaderComponent from "../../components/header.component.svelte";
    import { userInformation } from "../../auth.service";
    import { params } from "../../routeStore";

    import UserTypeComponent from "./user-types/component.svelte";
    import AdministratorsComponent from "./administrators/component.svelte";
    import CreateUpdateAdministratorComponent from "./administrators/create-update.component.svelte";
    import CreateUpdateUserTypeComponent from "./user-types/create-update.component.svelte";

    let loading = true;
    let activeTab = $params.activeTab || "administrators";
    let configTitle = {
        breadcrumb: [],
        title: ``,
        description: ``,
        buttons: [],
    };

    const tabsSteeps = [
        {
            codename: "administrators",
            name: "Administrators",
            component: AdministratorsComponent,
        },
        {
            codename: "user-types",
            name: "User types",
            component: UserTypeComponent,
        },
    ];
    let modalCreateUser = false;
    let modalCreateUserType = false;

    onMount(() => {
        loading = true;

        params.subscribe((param) => changeTabSelected(param.activeTab));
        initComponent();
    });

    const initComponent = () => {
        loading = true;

        setTimeout(() => {
            loading = false;
        }, 500);
    };

    const changeTabSelected = (codename) => {
        activeTab = codename || "administrators";
        configTitle.breadcrumb = [
            { title: `Home`, link: `/` },
            {
                title: `Settings`,
                link: `/settings`,
            }
        ];
        if (activeTab == "administrators") {
            configTitle.breadcrumb.push({
                title: `Administradores`,
                link: `/settings/access/administrators`,
            });
            configTitle.title = `Administrators`;
            configTitle.buttons = [
                {
                    icon: "add",
                    class: "btn-success",
                    action: {
                        type: "event",
                        event: "addAdmin",
                        detail: {},
                    },
                },
            ];
        } else if (activeTab == "user-types") {
            configTitle.breadcrumb.push({
                title: `Tipos de usuarios`,
                link: `/settings/access/user-types`,
            });
            configTitle.title = `Tipos de usuarios`;
            configTitle.buttons = [
                {
                    icon: "add",
                    class: "btn-success",
                    action: {
                        type: "event",
                        event: "addUserType",
                        detail: {},
                    },
                },
            ];
        } else {
            configTitle.buttons = [];
        }
    };

    const addUserTypeEvent = (e) => {
        modalCreateUserType = true;
    };

    const addAdminEvent = (e) => {
        modalCreateUser = true;
    };

    const CreateUpdateUserEvent = (response) => {
        modalCreateUser = false;
        modalCreateUserType = false;
        initComponent();
    };
</script>

{#if !loading}
    <section class="container d-flex flex-column mh-100">
        <div class="d-flex flex-column">
            <HeaderComponent
                bind:config={configTitle}
                on:addAdmin={addAdminEvent}
                on:addUserType={addUserTypeEvent}
            ></HeaderComponent>
        </div>

        <!-- Sección de contenido -->
        <div class="overflow-auto mh-100 flex-grow-1">
            <ul class="nav nav-tabs">
                {#each tabsSteeps as steep}
                    <li class="nav-item">
                        <a
                            class="nav-link {activeTab ===
                            steep.codename
                                ? 'active'
                                : ''}"
                            href="/settings/access/{steep.codename}"
                        >
                            {steep.name}
                        </a>
                    </li>
                {/each}
            </ul>

            <div class="card">
                <div class="card-body">
                    <!-- Contenido de Tabs -->
                    {#if tabsSteeps.find((t) => t.codename == activeTab)}
                        <svelte:component
                            this={tabsSteeps.find(
                                (t) => t.codename == activeTab,
                            ).component}
                            {...$$restProps}
                        />
                    {/if}
                </div>
            </div>
        </div>
    </section>
{/if}

{#if modalCreateUser}
    <CreateUpdateAdministratorComponent
        on:successful={CreateUpdateUserEvent}
        on:close={() => (modalCreateUser = false)}
    ></CreateUpdateAdministratorComponent>
{/if}

{#if modalCreateUserType}
    <CreateUpdateUserTypeComponent
        on:successful={CreateUpdateUserEvent}
        on:close={() => (modalCreateUserType = false)}
    ></CreateUpdateUserTypeComponent>
{/if}
