<script>
    import { onMount } from "svelte";
    import { _ } from "svelte-i18n";
    import {
        useForm,
        required,
        validators,
        email,
        minLength,
        Hint,
        HintGroup,
    } from "svelte-use-form";
    import { LoginService } from "./service";
    import { environments } from "../assets/environments";

    const dataForm = useForm();
    const dataLogin = {
        email: "",
        password: "",
        reminder: false,
    };
    let disabledButton = false;
    //let recaptchaToken = null;
    let passwordElement = null;
    let passwordIconElement = null;
    let errorRegister = 0;
    //let recaptchaWidgetId;
    //let recaptchaElement;

    // const recaptchaCheck = (event) => {
    //     recaptchaToken = event;
    // };

    // const onloadCallback = () => {
    //     recaptchaWidgetId = grecaptcha.render(recaptchaElement, {
    //         sitekey: environments.googleRecaptchaSiteKey,
    //         callback: recaptchaCheck,
    //         "error-callback": recaptchaError,
    //         "expired-callback": recaptchaError,
    //     });
    // };

    // const resetRecaptcha = () => {
    //     recaptchaToken = null;
    //     grecaptcha.reset(recaptchaWidgetId);
    // };

    // const recaptchaError = () => {
    //     resetRecaptcha();
    // };

    onMount(() => {
        // if (
        //     window.location.hostname != "localhost" &&
        //     !window.location.hostname.includes("norelian.com")
        // ) {
        //     window.recaptchaCheck = recaptchaCheck;
        //     window.onloadCallback = onloadCallback;
        //     window.recaptchaError = recaptchaError;
        // } else {
        //     recaptchaToken = true;
        // }
    });

    const onSubmit = (e) => {
        if ($dataForm.valid) {
            disabledButton = true;
            errorRegister = 0;
            LoginService.setLogin(
                Object.assign({}, dataLogin, {
                    "g-recaptcha-response": "dawdd"//recaptchaToken,
                }),
            ).catch((err) => {
                console.error(err);
                disabledButton = false;
                if (
                    !["localhost", "norelian.com"].includes(
                        window.location.hostname,
                    )
                ) {
                    //resetRecaptcha();
                }
                if (err.status == 401) {
                    errorRegister = 401;
                } else if (err.status == 403) {
                    errorRegister = 403;
                }
            });
        }
    };

    const toggleVisibility = (element, iconElement) => {
        if (element.type === "password") {
            iconElement.innerHTML = "visibility";
            element.type = "text";
        } else {
            iconElement.innerHTML = "visibility_off";
            element.type = "password";
        }
    };
</script>

<!-- <svelte:head>
    {#if !window.location.hostname != "localhost" && !window.location.hostname.includes("norelian.com")}
        <script
            src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
            async
            defer
        ></script>
    {/if}
</svelte:head> -->

<section class="vh-100">
    <div class="container-fluid">
        <div class="row">
            <div
                class="vh-100 col-sm-6 d-none d-sm-flex align-items-center justify-content-center vh-100"
                style="background: linear-gradient(142deg, #2F4050 5.7%, #1AB394 88.11%);"
            >
                <!-- svelte-ignore a11y-img-redundant-alt -->
                <img
                    class="w-100"
                    style="object-fit: cover; object-position: left;"
                    src="https://app.guesthub.io/assets/images/login-img.png"
                    alt="Login image"
                />
            </div>
            <div
                class="col-12 col-sm-6 d-flex align-items-center justify-content-center vh-100"
            >
                <div class="p-5 border rounded-3">
                    <h2>Inicia Sesión</h2>

                    {#if errorRegister == 401}
                        <div class="alert alert-danger" role="alert">
                            {$_(
                                "The email or password is incorrect. Please, try again.",
                            )}
                        </div>
                    {/if}

                    {#if errorRegister != 403}
                        <form use:dataForm on:submit|preventDefault={onSubmit}>
                            <div class="mb-3">
                                <label for="email" class="form-label">
                                    {$_("Email")}
                                </label>
                                <!-- svelte-ignore a11y-autofocus -->
                                <input
                                    use:validators={[required, email]}
                                    type="email"
                                    class="form-control {$dataForm.email
                                        ?.touched
                                        ? !$dataForm.email?.valid
                                            ? 'is-invalid'
                                            : 'is-valid'
                                        : ''}"
                                    name="email"
                                    id="email"
                                    required
                                    autofocus
                                    autocomplete="email"
                                    bind:value={dataLogin.email}
                                />
                                <HintGroup for="email">
                                    <Hint
                                        on="required"
                                        class="form-text invalid-feedback"
                                        >{$_("Email is required.")}</Hint
                                    >
                                    <Hint
                                        on="email"
                                        class="form-text invalid-feedback"
                                        hideWhen="required"
                                        >{$_(
                                            "The format of the email is not correct.",
                                        )}</Hint
                                    >
                                </HintGroup>
                            </div>
                            <div class="mb-3">
                                <label for="password" class="form-label">
                                    {$_("Password")}
                                </label>
                                <div class="input-group">
                                    <input
                                        bind:this={passwordElement}
                                        use:validators={[
                                            required,
                                            minLength(8),
                                        ]}
                                        type="password"
                                        class="form-control {$dataForm.password
                                            ?.touched
                                            ? !$dataForm.password?.valid
                                                ? 'is-invalid'
                                                : 'is-valid'
                                            : ''}"
                                        name="password"
                                        id="password"
                                        required
                                        autocomplete="current-password"
                                        bind:value={dataLogin.password}
                                    />
                                    <span class="input-group-text pointer">
                                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                                        <div
                                            class="material-icons"
                                            on:click|preventDefault={() =>
                                                toggleVisibility(
                                                    passwordElement,
                                                    passwordIconElement,
                                                )}
                                            bind:this={passwordIconElement}
                                        >
                                            visibility_off
                                        </div>
                                    </span>
                                </div>

                                <HintGroup for="password">
                                    <Hint
                                        on="required"
                                        class="form-text invalid-feedback"
                                        >{$_("Password is required.")}</Hint
                                    >
                                    <Hint
                                        on="minLength"
                                        class="form-text invalid-feedback"
                                        hideWhen="required"
                                        let:value
                                        >{$_(
                                            "The password must have at least {value} characters.",
                                        )}</Hint
                                    >
                                </HintGroup>
                                <div class="mt-2 mb-2">
                                    <a
                                        href="/forgot-password"
                                        class="text-decoration-none"
                                        >{$_("Forgot your password?")}</a
                                    >
                                </div>
                            </div>

                            <!-- <div class="mb-3 form-check">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="rememberMe"
                            checked={dataLogin.reminder}
                            on:change={(event) =>
                                (dataLogin.reminder = event.target.checked)}
                        />
                        <label class="form-check-label" for="rememberMe"
                            >{$_("Remember me")}</label
                        >
                    </div> -->

                            <!-- {#if !window.location.hostname != "localhost" && !window.location.hostname.includes("norelian.com")}
                                <div class="col-s12 recapchat">
                                    <div bind:this={recaptchaElement} />
                                </div>
                            {/if} -->

                            <div class="mt-3">
                                <button
                                    type="submit"
                                    class="btn btn-primary"
                                    disabled={!$dataForm.valid ||
                                        disabledButton}>{$_("Log In")}</button
                                >
                            </div>
                        </form>
                    {/if}
                </div>
            </div>
        </div>
    </div>
</section>
