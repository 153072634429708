<script>
    import { onMount } from "svelte";
    import { _ } from "svelte-i18n";
    import HeaderComponent from "../../components/header.component.svelte";
    import { userInformation } from "../../auth.service";
    import { params } from "../../routeStore";
    import ListComponent from "./component.svelte";
    import CreateUpdateSubscriptionComponent from "./create-update.component.svelte";

    let loading = true;
    let configTitle = {
        breadcrumb: [
            { title: `Home`, link: `/` },
            {
                title: `Settings`,
                link: `/settings`,
            },
            {
                title: `Subscriptions`,
                link: `/settings/subscriptions`,
            },
        ],
        title: `Subscriptions`,
        description: ``,
        buttons: [
            // {
            //     icon: "send",
            //     class: "btn-primary",
            //     action: {
            //         type: "event",
            //         event: "inviteSubscription",
            //         detail: {},
            //     },
            // },
            {
                icon: "add",
                class: "btn-success",
                action: {
                    type: "event",
                    event: "addAdmin",
                    detail: {},
                },
            },
        ],
    };
    let modalCreateUser = false;

    onMount(() => {
        loading = true;

        initComponent();
    });

    const initComponent = () => {
        loading = true;

        setTimeout(() => {
            loading = false;
        }, 500);
    };

    const addAdminEvent = (e) => {
        modalCreateUser = true;
    };

    const CreateUpdateUserEvent = (response) => {
        modalCreateUser = false;
        initComponent();
    };
</script>

<section class="container d-flex flex-column mh-100">
    <div class="d-flex flex-column">
        <HeaderComponent bind:config={configTitle} on:addAdmin={addAdminEvent}
        ></HeaderComponent>
    </div>

    <!-- Sección de contenido -->
    <div class="overflow-auto mh-100 flex-grow-1">
        <div class="card">
            <div class="card-body">
                <!-- Contenido de Tabs -->
                <ListComponent></ListComponent>
            </div>
        </div>
    </div>
</section>

{#if modalCreateUser}
    <CreateUpdateSubscriptionComponent
        on:successful={CreateUpdateUserEvent}
        on:close={() => (modalCreateUser = false)}
    ></CreateUpdateSubscriptionComponent>
{/if}
