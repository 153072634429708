<script>
    import { onMount } from "svelte";
    import { _ } from "svelte-i18n";
    import HeaderComponent from "../components/header.component.svelte";
    import { redirect } from "../routeStore";

    let loading = true;
    let configTitle = {
        breadcrumb: [
            { title: `Home`, link: `/` },
            {
                title: `Settings`,
                link: `/settings`,
            },
        ],
        title: `Settings`,
        description: ``,
        buttons: [],
    };

    onMount(() => {
        loading = true;

        initComponent();
    });

    const initComponent = () => {
        loading = true;
        loading = false;
    };

    const goToFeature = (feature) => {
        switch (feature.codename) {
            case "access":
                redirect("/settings/access/administrators");
                break;
            case "subscriptions":
                redirect("/settings/subscriptions");
                break;

            default:
                break;
        }
    };

    const listFeatures = [
        {
            codename: "access",
            icon: "security",
            name: "Accesos",
            description:
                "Gestiona quién puede acceder a esta plataforma y con qué privilegios.",
        },
        {
            codename: "subscriptions",
            icon: "subscriptions",
            name: "Subscriptions",
            description: "Gestiona todas las subscripciónes de GuestHub.",
        },
    ];
</script>

{#if !loading}
    <section class="container d-flex flex-column overflow-auto mh-100">
        <div class="d-flex flex-column">
            <HeaderComponent bind:config={configTitle}></HeaderComponent>
        </div>

        <!-- Sección de contenido -->
        <div class="flex-grow-1">
            <div class="row">
                {#each listFeatures as feature}
                    <div class="col-12 col-md-6 col-lg-4 d-flex mb-3">
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <div
                            class="card border-left-green w-100 d-flex align-items-center justify-content-center text-center pointer"
                            on:click|preventDefault={() => goToFeature(feature)}
                        >
                            <div
                                class="card-body d-flex flex-column justify-content-center align-items-center"
                            >
                                <span
                                    class="material-icons"
                                    style="font-size: 3rem;"
                                    >{feature.icon}</span
                                >
                                <h5 class="card-title mt-3">{feature.name}</h5>
                                <p class="card-text">{feature.description}</p>
                            </div>
                        </div>
                    </div>
                {/each}
            </div>
        </div>
    </section>
{/if}
